<template>
    <section class="row w-100">
        <div class="d-flex flex-column align-items-center justify-content-start w-100 py-5"
            data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="600"
        >
            <h3 class="main-title w-100 text-center">PROJECTS</h3>
            <p class="main-subtitle w-100 text-center">Few of the projects that I developed</p>
        </div>
        <div v-for="(project, idx) in projects" :key="`project_${idx}`" class="row w-100 m-auto">
            <hr>
            <div class="container project-section">
                <div class="row align-items-center h-100 text-center text-lg-left overflow-hidden">
                    <div class="col-lg-6 p-lg-0" data-aos="fade-right" data-aos-easing="ease-out-cubic" data-aos-duration="400">
                        <img class="card-img" :src="require(`./../assets/work/web/${project.image}`)" alt="">
                    </div>
                    <div class="col-lg-5 mx-auto py-3" data-aos="fade-left" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="100">
                        <h5 class="card-title">{{ project.title }}</h5>
                        <p class="card-text">{{ project.description }}</p>

                        <div class="d-flex flex-row align-items-center justify-content-center flex-wrap w-100 hidden">
                            <div v-for="(tech, tech_idx) in project.technologies" :key="`project_${idx}_tech_${tech_idx}`" class="py-3 tech-icon">
                                <img :src="require(`./../assets/work/technologies/${tech}`)" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            projects: [
                {
                    title: 'CafeBooking',
                    description: 'A system to handle real-time cafe booking.',
                    image: 'cafebooking.png',
                    technologies: ['laravel.png', 'vue-icon.svg', 'tailwind-icon.svg', 'pusher-icon.png']
                },
                {
                    title: 'GetEnrolled',
                    description: 'A system to enrol to a university.',
                    image: 'getenrolled.png',
                    technologies: ['laravel.png', 'vue-icon.svg', 'tailwind-icon.svg']
                },
                {
                    title: 'Industrial Scheduling',
                    description: 'A system to schedule for Industrial Scheduling visits.',
                    image: 'industrialscheduling.png',
                    technologies: ['laravel.png', 'vue-icon.svg', 'tailwind-icon.svg']
                },
                {
                    title: 'Wedding Planner',
                    description: 'A system for public to find vendors for their wedding.',
                    image: 'weddingplanner.png',
                    technologies: ['laravel.png', 'vue-icon.svg', 'bootstrap-icon.svg', 'sass-icon.svg']
                },
                {
                    title: 'Parking Management',
                    description: 'A system to manage parking lots in real-time.',
                    image: 'parkingmanagement.png',
                    technologies: ['laravel.png', 'vue-icon.svg', 'bootstrap-icon.svg', 'sass-icon.svg', 'pusher-icon.png']
                },
                {
                    title: 'Service Management',
                    description: 'A system to hire people for services.',
                    image: 'servicemanagement.png',
                    technologies: ['laravel.png', 'vue-icon.svg', 'tailwind-icon.svg', 'react-native-icon.png', 'pusher-icon.png']
                }
            ]
        }
    }
}
</script>

<style lang="css" scoped>
    .tech-icon {
        padding: 2%;
    }

    .tech-icon>img {
        height: 40px;
        width: 100px;
        object-fit: contain;
    }

    .project-section {
        padding-top: 100px;
        padding-bottom: 100px;
    }
</style>