<template>
    <section class="d-flex flex-column align-items-center justify-content-center text-white gradient-background">
        <div class="d-flex flex-column align-items-center justify-content-start w-100 py-5"
            data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="600"
        >
            <h3 class="main-title w-100 text-center ignore-bg">GET IN TOUCH</h3>
            <p class="main-subtitle w-100 text-center">Got something to talk? Send a message now</p>
        </div>
        <div class="container" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="600">
            <form ref="contact_form" @submit.prevent="sendEmail">
                <div class="row pb-3">
                    <div class="col-md-6 pr-md-2">
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input type="text" name="name" class="form-control" required="" id="name">
                        </div>
                    </div>
                    <div class="col-md-6 pr-md-2">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="email" name="email" class="form-control" required="" id="email">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <label for="message">Message</label>
                        <textarea name="message" class="form-control" rows="5" id="message" required=""></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="d-flex flex-row align-items-center justify-content-between mt-4">
                        <div class="d-flex flex-row align-items-center justify-content-start ">
                            <a href="https://www.facebook.com/tharavink.prasad/" target="_blank">
                                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" size="lg" class="social-icon" />
                            </a>
                            <a href="https://www.linkedin.com/in/tharavink-prasad-sivarajan-00a979157/" target="_blank">
                                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin' }" size="lg" class="social-icon" />
                            </a>
                            <a href="https://www.instagram.com/tharavink/" target="_blank">
                                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }" size="lg" class="social-icon" />
                            </a>
                        </div>

                        <button v-if="submitting" type="submit" class="btn submit-btn with-icon" :disabled="true"> 
                            <div class="lds-ring d-flex align-items-center justify-content-center">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <span class="pl-5">Sending</span>
                        </button>
                        <button v-else type="submit" class="btn submit-btn"> 
                            <span>Submit Form</span>
                        </button>
                    </div>
                </div>
                <div class="row pt-3 px-2">
                    <div id="email-error" class="alert alert-danger" hidden>
                        An error occurred. Alternatively, you can email to me at tharavink619@gmail.com
                    </div>
                    <div id="email-success" class="alert alert-success" hidden>
                        Message sent successfully. Thank you so much for reaching out to me.
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
    data() {
        return {
            submitting: false
        }
    },
    methods: {
        sendEmail() {
            this.submitting = true
            let self = this
            emailjs.sendForm('service_c7w4nok', 'template_ijac9ln', this.$refs.contact_form, 'user_trdzBK36d1gqkYwETPm8a')
                .then(() => {
                    document.getElementById('email-success').removeAttribute('hidden')
                    self.submitting = false
                }, () => {
                    document.getElementById('email-error').removeAttribute('hidden')
                    self.submitting = false
                });
        }
    }
}
</script>

<style>
    input[type=text], input[type=email], textarea {
        font-size: 1.5rem;
        padding: 5px;
    }
</style>