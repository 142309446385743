<template>
	<div class="main-section">
		<Intro />
		<div id="floating-navbar" class="d-flex flex-row align-items-end justify-content-end gradient-background"
			data-aos="fade-in" data-aos-easing="ease-out-cubic" data-aos-duration="1000" data-aos-anchor="#intro-wave"
		>
			<div class="container d-flex flex-column w-100 align-items-end justify-content-center">
				<Navigator class="text-white" />
			</div>
		</div>
		<Expertise />
		<Projects id="projects-section" />
		<Contact id="contact-section" />
	</div>
</template>

<script>
import Intro from './Intro.vue'
import Expertise from './Expertise.vue'
import Navigator from './Navigator.vue'
import Projects from './Projects.vue'
import Contact from './Contact.vue'

export default {
	name: 'Portfolio',
	components: {
		Intro,
		Expertise,
		Navigator,
		Projects,
		Contact
	}
}
</script>

<style scoped>
	#floating-navbar {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 10;
	}
</style>
