var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"d-flex flex-column align-items-start justify-content-start text-dark container",attrs:{"id":"expertise-section"}},[_vm._m(0),_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col-md-6 col-sm-12 col-xs-12 py-3",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-out-cubic","data-aos-duration":"600"}},[_c('label',{staticClass:"main-subtitle pb-2 w-100 text-center"},[_vm._v("Web Backend Frameworks")]),_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start w-100"},_vm._l((_vm.web_frameworks),function(ref,idx){
var img = ref.img;
var value = ref.value;
return _c('div',{key:("framework_" + idx),staticClass:"tech-div"},[_c('img',{attrs:{"src":require(("/src/assets/web-icons/" + img)),"alt":""}}),_c('div',{staticClass:"progress w-75 pl-4"},[_c('div',{staticClass:"progress-bar",attrs:{"role":"progressbar","data-aos":("width-" + value),"data-aos-easing":"ease-out-cubic","data-aos-duration":"1000","aria-valuenow":value,"aria-valuemin":"0","aria-valuemax":"100"}})])])}),0)]),_c('div',{staticClass:"col-md-6 col-sm-12 col-xs-12 py-3",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-out-cubic","data-aos-duration":"600"}},[_c('label',{staticClass:"main-subtitle pb-2 w-100 text-center"},[_vm._v("Mobile Frameworks")]),_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start w-100"},_vm._l((_vm.mobile_frameworks),function(ref,idx){
var img = ref.img;
var value = ref.value;
return _c('div',{key:("framework_" + idx),staticClass:"tech-div"},[_c('img',{attrs:{"src":require(("/src/assets/mobile-icons/" + img)),"alt":""}}),_c('div',{staticClass:"progress w-75 pl-4"},[_c('div',{staticClass:"progress-bar bg-success",attrs:{"role":"progressbar","data-aos":("width-" + value),"data-aos-easing":"ease-out-cubic","data-aos-duration":"1000","aria-valuenow":value,"aria-valuemin":"0","aria-valuemax":"100"}})])])}),0)]),_c('div',{staticClass:"col-md-6 col-sm-12 col-xs-12 py-3",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-out-cubic","data-aos-duration":"600"}},[_c('label',{staticClass:"main-subtitle pb-2 w-100 text-center"},[_vm._v("JS Frameworks")]),_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start w-100"},_vm._l((_vm.js_frameworks),function(ref,idx){
var img = ref.img;
var value = ref.value;
return _c('div',{key:("framework_" + idx),staticClass:"tech-div"},[_c('img',{attrs:{"src":require(("/src/assets/js-icons/" + img)),"alt":""}}),_c('div',{staticClass:"progress w-75 pl-4"},[_c('div',{staticClass:"progress-bar bg-success",attrs:{"role":"progressbar","data-aos":("width-" + value),"data-aos-easing":"ease-out-cubic","data-aos-duration":"1000","aria-valuenow":value,"aria-valuemin":"0","aria-valuemax":"100"}})])])}),0)]),_c('div',{staticClass:"col-md-6 col-sm-12 col-xs-12 py-3",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-out-cubic","data-aos-duration":"600"}},[_c('label',{staticClass:"main-subtitle pb-2 w-100 text-center"},[_vm._v("CSS Frameworks and Preprocessors")]),_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start w-100"},_vm._l((_vm.css_frameworks),function(ref,idx){
var img = ref.img;
var value = ref.value;
return _c('div',{key:("framework_" + idx),staticClass:"tech-div"},[_c('img',{attrs:{"src":require(("/src/assets/css-icons/" + img)),"alt":""}}),_c('div',{staticClass:"progress w-75"},[_c('div',{staticClass:"progress-bar",attrs:{"role":"progressbar","data-aos":("width-" + value),"data-aos-easing":"ease-out-cubic","data-aos-duration":"1000","aria-valuenow":value,"aria-valuemin":"0","aria-valuemax":"100"}})])])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-items-center justify-content-start w-100 pt-5",attrs:{"data-aos":"fade-down","data-aos-easing":"ease-out-cubic","data-aos-duration":"600"}},[_c('h3',{staticClass:"main-title w-100 text-center"},[_vm._v("MY EXPERTISE")]),_c('p',{staticClass:"main-subtitle w-100 text-center"},[_vm._v("Proficiency in Programming Languages and Frameworks")])])}]

export { render, staticRenderFns }