<template>
    <div class="d-flex flex-row align-items-center justify-content-around navbar w-100"
        data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="600"
    >
        <div class="py-2"  data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" data-aos-delay="300">
            <a href="#intro-section" v-smooth-scroll>Home</a>
        </div>
        <div class="py-2"  data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" data-aos-delay="400">
            <label for="">|</label>
        </div>
        <div class="py-2"  data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" data-aos-delay="500">
            <a href="#expertise-section" v-smooth-scroll>Expertise</a>
        </div>
        <div class="py-2"  data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" data-aos-delay="600">
            <label for="">|</label>
        </div>
        <div class="py-2"  data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" data-aos-delay="700">
            <a href="#projects-section" v-smooth-scroll>Projects</a>
        </div>
        <div class="py-2"  data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" data-aos-delay="800">
            <label for="">|</label>
        </div>
        <div class="py-2"  data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="700" data-aos-delay="900">
            <a href="#contact-section" v-smooth-scroll>Contact</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar'
}
</script>

<style>

</style>