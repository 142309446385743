<template>
    <section id="expertise-section" class="d-flex flex-column align-items-start justify-content-start text-dark container">
        <div class="d-flex flex-column align-items-center justify-content-start w-100 pt-5"
            data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="600"
        >
            <h3 class="main-title w-100 text-center">MY EXPERTISE</h3>
            <p class="main-subtitle w-100 text-center">Proficiency in Programming Languages and Frameworks</p>
        </div>
        <div class="row w-100">
            <div class="col-md-6 col-sm-12 col-xs-12 py-3"
                data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="600"
            >
                <label class="main-subtitle pb-2 w-100 text-center">Web Backend Frameworks</label>

                <div class="d-flex flex-column justify-content-start align-items-start w-100">
                    <div v-for="({ img, value }, idx) in web_frameworks" :key="`framework_${idx}`" class="tech-div">
                        <img :src="require(`/src/assets/web-icons/${img}`)" alt="">

                        <div class="progress w-75 pl-4">
                            <div class="progress-bar" role="progressbar"  :data-aos="`width-${value}`" data-aos-easing="ease-out-cubic" data-aos-duration="1000" :aria-valuenow="value" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 py-3"
                data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="600"
            >
                <label class="main-subtitle pb-2 w-100 text-center">Mobile Frameworks</label>

                <div class="d-flex flex-column justify-content-start align-items-start w-100">
                    <div v-for="({ img, value }, idx) in mobile_frameworks" :key="`framework_${idx}`" class="tech-div">
                        <img :src="require(`/src/assets/mobile-icons/${img}`)" alt="">

                        <div class="progress w-75 pl-4">
                            <div class="progress-bar bg-success" role="progressbar"  :data-aos="`width-${value}`" data-aos-easing="ease-out-cubic" data-aos-duration="1000" :aria-valuenow="value" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 py-3"
                data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="600"
            >
                <label class="main-subtitle pb-2 w-100 text-center">JS Frameworks</label>

                <div class="d-flex flex-column justify-content-start align-items-start w-100">
                    <div v-for="({ img, value }, idx) in js_frameworks" :key="`framework_${idx}`" class="tech-div">
                        <img :src="require(`/src/assets/js-icons/${img}`)" alt="">

                        <div class="progress w-75 pl-4">
                            <div class="progress-bar bg-success" role="progressbar"  :data-aos="`width-${value}`" data-aos-easing="ease-out-cubic" data-aos-duration="1000" :aria-valuenow="value" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 py-3"
                data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="600"
            >
                <label class="main-subtitle pb-2 w-100 text-center">CSS Frameworks and Preprocessors</label>

                <div class="d-flex flex-column justify-content-start align-items-start w-100">
                    <div v-for="({ img, value }, idx) in css_frameworks" :key="`framework_${idx}`" class="tech-div">
                        <img :src="require(`/src/assets/css-icons/${img}`)" alt="">

                        <div class="progress w-75">
                            <div class="progress-bar" role="progressbar" :data-aos="`width-${value}`" data-aos-easing="ease-out-cubic" data-aos-duration="1000" :aria-valuenow="value" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            web_frameworks: [
                { img: 'laravel.png', value: 90 },
                { img: 'express-icon.png', value: 70 },
                { img: 'codeigniter.svg', value: 60 },
                { img: 'spring-icon.png', value: 60 },
                { img: 'aspnet-icon.png', value: 50 }
            ],
            css_frameworks: [
                { img: 'bootstrap-icon.svg', value: 90 },
                { img: 'tailwind-icon.svg', value: 90 },
                { img: 'sass-icon.svg', value: 90 }
            ],
            js_frameworks: [
                { img: 'vue-icon.svg', value: 80 },
                { img: 'react-icon.svg', value: 70 },
                { img: 'jquery-icon.svg', value: 80 }
            ],
            mobile_frameworks: [
                { img: 'react-native-icon.png', value: 90 },
                { img: 'flutter-icon.png', value: 50 }
            ]
        }
    }
}
</script>

<style>
    #expertise-section {
        width: 100vw;
        background: #fff;
        color: #fff;
    }

    .tech-div {
        padding: 1rem;

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .tech-div>img {
        width: 5rem;
        height: 3rem;
        object-fit: contain;
    }
</style>