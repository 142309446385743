import Vue from 'vue'
import App from './App.vue'

import AOS from "aos";
import "aos/dist/aos.css";

import VueRellax from 'vue-rellax'
Vue.use(VueRellax)

import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueSmoothScroll, {
  duration: 300,
  offset: -50,
  updateHistory: false,
})

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebook)
library.add(faLinkedin)
library.add(faInstagram)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  created() {
    AOS.init();
  },
  render: h => h(App),
}).$mount('#app')
