<template>
    <section id="intro-section" class="row w-100 text-dark m-0 p-0">
        <div class="col-md-12 m-auto hero-section pb-4">
            <div class="container d-flex flex-column align-items-start justify-content-center px-3 pb-5">
                <h1 class="main-title" data-aos="fade-in" data-aos-easing="ease-out-cubic" data-aos-duration="600" data-aos-delay="300">Hi, I am Tharavink</h1>
                <h4 class="main-subtitle" data-aos="fade-in" data-aos-easing="ease-out-cubic" data-aos-duration="600" data-aos-delay="500">Web and Mobile Developer</h4>
                <p for="" data-aos="fade-in" data-aos-easing="ease-out-cubic" data-aos-duration="500" data-aos-delay="800">
                    Hi, my name is Tharavink and I am a passionated young software developer. I have over 5 years of experience
                    in the web and mobile development.
                </p>
                <hr class="divider" data-aos="flip-down" data-aos-easing="ease-out-cubic" data-aos-duration="300" data-aos-delay="1000" />
                <Navigator class="text-dark" />
            </div>
        </div>
        <div
            class="absolute-div side-diamond left-diamond"
            data-aos="fade-up-right" 
            data-aos-easing="ease-out-cubic" 
            data-aos-duration="1000"
            v-rellax="{
                speed: 2
            }"
        >
            <div class="diamond"></div>
        </div>
        <div
            class="absolute-div side-diamond right-diamond"
            data-aos="fade-up-left" 
            data-aos-easing="ease-out-cubic" 
            data-aos-duration="1000"
            v-rellax="{
                speed: 2
            }"
        >
            <div class="diamond"></div>
        </div>

        <div id="intro-wave" class="absolute-div p-0" style="bottom: 0px" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="p-0 m-auto mb-0">
                <path fill="#750ff8" fill-opacity="1" d="M0,128L48,144C96,160,192,192,288,181.3C384,171,480,117,576,106.7C672,96,768,128,864,160C960,192,1056,224,1152,197.3C1248,171,1344,85,1392,42.7L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>
        </div>
  </section>
</template>

<script>
import Navigator from './Navigator.vue'

export default {
    name: 'Intro',
    components: {
        Navigator
    }
}
</script>

<style lang="css" scoped>
    #intro-section {
        width: 100vw;
        height: 100vh;
        color: #fff;
        position: relative;
        overflow: hidden;
    }

    .hero-section {
        z-index: 3;
    }

    .divider {
        width: 100%;
        height: .5rem;
    }
</style>